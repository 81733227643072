import { Carousel } from '../components/Carousel';
import About from '../components/About';
import slides from '../data/carouselData.json';
import { Hero } from '../components/Hero';
import { Service } from '../components/Service';
import './Home.css';
// import { Canvas } from '../components/Canvas';

export default function Home() {
    return (
        <>
            <div className="bordered-container">
                <Hero />
                <Service />
                <Carousel data={ slides } />
                <About />
            </div>
        </>
    );
}