import App from '../App';
import './Gallery.css';

export default function Gallery() {
    return (
        <>
            <main className="grid-container">
                <article className="grid-object grid-col-span-2 blue">
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A necessitatibus sequi quisquam possimus dignissimos ab architecto, tenetur repellat minus atque labore laudantium eveniet quo quae voluptatibus fugiat molestiae veniam. Facilis?</p>
                    </div>
                </article>
                <article className="grid-object green">
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A necessitatibus sequ</p>
                    </div>
                </article>
                <article className="grid-object gold">
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A necessitatibus sequi quisquam possimus dignissimos ab architecto, tenetur repellat minus atque labore laudantium eveniet quo quae voluptatibus fugiat molestiae veniam. Facilis?</p>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore aliquid quisquam quos quam voluptate voluptatem, voluptates necessitatibus dolorum atque. Laudantium deserunt fuga dolorem eaque alias. Repellat veniam qui velit magni.</p> */}
                    </div>
                </article>
                <article className="grid-object grid-col-span-2 purple">
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A necessitatibus sequi quisquam possimus dignissimos ab architecto, tenetur repellat minus atque labore laudantium eveniet quo quae voluptatibus fugiat molestiae veniam. Facilis?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore aliquid quisquam quos quam voluptate voluptatem, voluptates necessitatibus dolorum atque. Laudantium deserunt fuga dolorem eaque alias. Repellat veniam qui velit magni.</p>
                    </div>
                </article>
                <article className="grid-object last-color">
                    <div>
                        <img src="" alt="" />
                    </div>
                    <div>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. A necessitatibus sequi quisquam possimus dignissimos ab architecto, tenetur repellat minus atque labore laudantium eveniet quo quae voluptatibus fugiat molestiae veniam. Facilis?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore aliquid quisquam quos quam voluptate voluptatem, voluptates necessitatibus dolorum atque. Laudantium deserunt fuga dolorem eaque alias. Repellat veniam qui velit magni.</p>
                    </div>
                </article>
            </main>
        </>
    );
}