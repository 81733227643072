import React from "react";
import './About.css'; 

export default function Footer() {
    return (
        <>
            {/* <hr className="breaker" /> */}
            <div className="about-container"></div>
            <div className="about">
                <p>
                    Ni hittar mig och min verkstad i monteringshallen i Sickla köpkvarter.
                </p>
                <p>
                    Jag tar emot reparationer och renoveringar av möbler, inredningsdetaljer, väskor och annat i läder.
                </p>
                <p>
                Tveka inte att höra av er angående en prisuppgift. Skicka relevanta foton och beskriv vad som önskas så svarar jag. Skriv på instagram eller maila mig.
                </p>
            </div>
            <hr className="breaker" />
        </>
    );
}