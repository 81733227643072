import './Piano.css';

export default function Piano() {
    return (
        <>
            <div className="wrapper">
                <div id="p">
                    <div className="white-keys">
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                        <div className="w-key"></div>
                    </div>
                    <div className="black-keys">
                        <div className="b-key"></div>
                        <div className="b-key"></div>
                        <div className="t-key"></div>
                        <div className="b-key"></div>
                        <div className="b-key"></div>
                        <div className="b-key"></div>
                    </div>
                </div>
            </div>
        </>
    );
}