import React, { useEffect } from 'react';
import './Hero.css';

export const Hero = () => {
  return (
      <>
          
        <div className="hero">
          <h1 className="hero-text">
            Börjer Börjersson - Skeppsmakaren i Skeppsta
          </h1>
          <p>Bla blabl abl</p>
        </div>

        {/* <hr className="breaker" /> */}
        
        {/* <p className="hero-text">
          - Skeppsmakaren i Skeppsta
        </p> */}
      </>
  );
}