import React from 'react';
import './Service.css';

export const Service = () => {
    return (
        <>
            <div className="service-wrapper">
                <div className="service-object">
                    {/* <img src="pictures/repair-svgrepo-com.svg" alt="" 
                        height="25px"
                        fill="#e06329" /> */}
                    <object type="image/svg+xml" data="pictures/repair-tools-svgrepo-com.svg" class="my-svg" width="35" height="35"></object>
                    {/* <svg xmlns="pictures/repair-svgrepo-com.svg" width="18" height="18" /> */}
                    <p>
                        Jag utför reparationer av väskor, stolar och andra lädervaror.
                    </p>
                </div>
                <div className="service-object">
                <object type="image/svg+xml" data="pictures/speech-svgrepo-com.svg" class="my-svg" width="35" height="35"></object>
                <p>
                        Hör av dig över mejl med detaljer om arbete du vill ha utfört eller kom förbi min verkstad. 
                    </p>
                </div>
            </div>
        </>
    );
}