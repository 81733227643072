import React from 'react';
import '../App.css';
import './Header.css';
import Home from '../pages/Home';
import Gallery from '../pages/Gallery';
//import Hamburger from '../pages/Hamburger';
//import Piano from '../pages/Piano';
//import logo from 'pictures/instagram-logo-thin-svgrepo-com.svg';
import { NavLink } from 'react-router-dom';

function Header() {
  return (
    <div className="sticky-header">
      <nav>
        <ul id="primary-navigation">
          <NavLink to="home" element={<Home />} className={({isActive}) => 
          `${isActive ? "isActiveColor" : "notActiveColor"}`}>
            <span aria-hidden="true">Hem</span>
          </NavLink>
          <NavLink to="gallery" element={<Gallery />} className={({isActive}) => 
          `${isActive ? "isActiveColor" : "notActiveColor"}`}>
            <span aria-hidden="true">Galleri</span>
          </NavLink>
          
          
          {/* <NavLink to="hamburger" element={<Hamburger />} className={({isActive}) => 
          `${isActive ? "isActiveColor" : "notActiveColor"}`}>
            <span aria-hidden="true">Hamburger</span>
          </NavLink>
          <NavLink to="piano" element={<Piano />} className={({isActive}) =>
          `${isActive ? "isActiveColor": "notActiveColor"}`}>
            <span aria-hidden="true">Piano</span>
          </NavLink> */}
        </ul>
    
      </nav>
      <a href="https://www.instagram.com/birgerpalmqvist/?igsh=eG9sbm92bGI2MnM2" target="_blank"> 
        <img 
          src="pictures/1161953_instagram_icon.svg"
          width="38"
          height="38"
          alt="Instagram Icon" 
          fill="#898989"
        />
      </a>
      
    </div>
    
  );
}

export default Header;
