import './Hamburger.css';

export default function Gallery() {
    return (
        <>
            <div className="hamburger-container">
                {/* Button one */}
                <button className="button-one"
                        aria-controls="primary-navigation"
                        aria-expanded="false">
                    <svg fill="var(--button-color)"
                         className="hamburger"
                         viewBox="0 0 100 100"
                         width="250">
                        <rect className="line top"
                              width="80"
                              height="10"
                              x="10"
                              y="25"
                              rx="5">
                        </rect>
                        <rect className="line middle"
                              width="80"
                              height="10"
                              x="10"
                              y="45"
                              rx="5">
                        </rect>
                        <rect className="line bottom"
                              width="80"
                              height="10"
                              x="10"
                              y="65"
                              rx="5">
                        </rect>
                    </svg>
                </button>

                {/* Button two */}
                <button className="button-two"
                        aria-controls="primary-navigation"
                        aria-expanded="false">
                    <svg stroke="var(--button-color)"
                         className="hamburger"
                         viewBox="0 0 100 100"
                         width="250">
                        <line className="line top"
                            x1="15" x2="85" y1="40" y2="40"
                            stroke-width="10"
                            stroke-linecap="round">
                        </line>
                        <line className="line middle"
                            x1="15" x2="85" y1="60" y2="60"
                            stroke-width="10"
                            stroke-linecap="round">
                        </line>
                    </svg>

                </button>


            </div>
        </>
    );
}